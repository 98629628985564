import { useTranslation } from "@equiem/web-ng-lib";
import { Button, EscapedHtmlDiv, useShowError } from "@equiem/web-ng-lib";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Envelope } from "../icons/Envelope";
import { Tick } from "../icons/Tick";
import { Props as RoundButtonProps, RoundButton } from "../RoundButton";
import { useRsvpMutations } from "./useRsvpMutations";

interface Props extends Pick<RoundButtonProps, "focusable" | "shadow"> {
  uuid: string;
  rsvpedByMe: boolean;
  hasCapacity: boolean;
  hasRsvpTerms: boolean;
  className?: string;
  eventPost?: {
    termsAndConditions?: string | null;
  } | null;
}

export const RsvpButton: React.FC<Props> = ({
  uuid,
  rsvpedByMe,
  hasRsvpTerms,
  hasCapacity,
  eventPost,
  focusable,
  shadow,
  className,
}) => {
  const { t } = useTranslation();
  const showError = useShowError();
  const { cancelRsvp, addRsvp, cancellingRsvp, addingRsvp } = useRsvpMutations({ occurrenceUuid: uuid });
  const [showTandCs, setShowTandCs] = useState(false);

  // Only allow rsvp when the event is not full or rsvped.
  const allowRsvp = hasCapacity || rsvpedByMe;

  const toggleRsvp = async () => {
    try {
      if (rsvpedByMe) {
        await cancelRsvp();
      }
      else if (!hasRsvpTerms || eventPost?.termsAndConditions == null) {
        await addRsvp({ acceptTermsAndConditions: true }, t);
      }
      else {
        setShowTandCs(true);
      }
    }
    catch (e) {
      showError(e);
    }
  };

  const loading = addingRsvp || cancellingRsvp;

  return (
    <>
      <RoundButton
        loading={loading}
        onToggle={toggleRsvp}
        value={rsvpedByMe}
        className={`btn-rsvp ${className}`}
        shadow={shadow}
        focusable={focusable}
        label={allowRsvp ? t("main.rsvp") : t("main.eventFull")}
        labelClassName={allowRsvp ? undefined : "mt-1"}
        disabled={!allowRsvp || loading}
      >
        {rsvpedByMe ? <Tick /> : allowRsvp ? <Envelope size={22} /> : null}
      </RoundButton>
      <Modal
        show={showTandCs}
        keyboard={true}
        size="lg"
        scrollable={true}
        centered={true}
        onHide={() => {
          setShowTandCs(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("common.termsAndConditions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EscapedHtmlDiv html={eventPost?.termsAndConditions} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline"
            onClick={() => {
              setShowTandCs(false);
            }}
          >
            {t("main.decline")}
          </Button>
          <Button
            variant="solid"
            onClick={async () => {
              setShowTandCs(false);
              await addRsvp({ acceptTermsAndConditions: true }, t);
            }}
          >
            {t("main.accept")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
